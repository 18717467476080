import React, { forwardRef, useRef } from "react";

import { cn } from "src/@/lib/utils";
import { AnimatedBeam } from "src/@/components/ui/animated-beam";
import { Box } from "@mui/material";
import logo from "../../assets/iHomies-img/logo-only.png";
import AmericaBank from "../../assets/image/image 9.png";
import CitiBank from "../../assets/image/image 19.png";
import DeutscheBank from "../../assets/image/image 18.png";
import HsbcBank from "../../assets/image/2.png";
import IngBank from "../../assets/image/image 8.png";
import UbsBank from "../../assets/image/image 20.png";

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export function AnimatedBeamDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex min-w-[300px] min-h-[300px] w-full items-center justify-center overflow-hidden rounded-lg p-10 "
      ref={containerRef}
    >
      <div className="flex size-full flex-col max-w-lg items-stretch justify-between gap-8">
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div1Ref} className="size-16">
            <Icons.googleDrive />
          </Circle>
          <Circle ref={div5Ref} className="size-16">
            <Icons.googleDocs />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div2Ref} className="size-16">
            <Icons.notion />
          </Circle>
          <Circle ref={div4Ref} className="size-16">
            <Icons.openai />
          </Circle>
          <Circle ref={div6Ref} className="size-16">
            <Icons.zapier />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div3Ref} className="size-16">
            <Icons.whatsapp />
          </Circle>
          <Circle ref={div7Ref} className="size-16">
            <Icons.messenger />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
        reverse
      />
    </div>
  );
}

const Icons = {
  notion: () => <Box component="img" src={CitiBank} />,
  openai: () => <Box component="img" src={logo} />,
  googleDrive: () => <Box component="img" src={AmericaBank} />,
  whatsapp: () => <Box component="img" src={HsbcBank} />,
  googleDocs: () => <Box component="img" src={IngBank} />,
  zapier: () => <Box component="img" src={DeutscheBank} />,
  messenger: () => <Box component="img" src={UbsBank} />,
};
