import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import useDebounce from "src/hooks/useDebounce";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";

interface Column {
  id: "name" | "email" | "role";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "email", label: "E-mail", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },
];

function UserList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 400);

  const fetchUsers = async (term = "") => {
    setLoading(true);
    try {
      let querySnapshot;

      if (term) {
        const nameQuery = await firebase
          .firestore()
          .collection("users")
          .where("name", ">=", term)
          .where("name", "<=", term + "\uf8ff")
          .get();

        const emailQuery = await firebase
          .firestore()
          .collection("users")
          .where("email", ">=", term)
          .where("email", "<=", term + "\uf8ff")
          .get();

        const nameResults = nameQuery.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const emailResults = emailQuery.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const allResults = [...nameResults, ...emailResults];

        const uniqueResults: any = Array.from(
          new Map(allResults.map((user) => [user.id, user])).values()
        );

        setUsers(uniqueResults);
      } else {
        // Fetch all users
        querySnapshot = await firebase.firestore().collection("users").get();
        const userList: any = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userList);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUsers(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRoleChange = async (user: any) => {
    const newRole = user.role === "admin" ? "user" : "admin";
    setLoading(true);
    try {
      await firebase.firestore().collection("users").doc(user.id).update({
        role: newRole,
      });

      toast.success("User Role Updated Successfully", {
        duration: 4000,
        position: "bottom-right",

        style: {},
        className: "",

        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
      fetchUsers();
      setLoading(false);
    } catch (error) {
      console.error("Error updating role:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="h4">Users</Typography>
      </Box>

      <input
        type="text"
        placeholder="Search by name or email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          margin: "5px 0",
          padding: "8px",
          width: "300px",
          border: "solid black 2px",
          borderRadius: "5px",
        }}
      />

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: any) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          handleRoleChange(user);
                        }}
                        variant="contained"
                        sx={{
                          backgroundColor:
                            user.role === "admin" ? "red" : "green",
                          "&:hover": {
                            backgroundColor:
                              user.role === "admin" ? "darkred" : "darkgreen",
                          },
                          color: "white",
                          textTransform: "none",
                          padding: "8px 12px",
                          borderRadius: "4px",
                        }}
                      >
                        {user.role === "admin" ? "Make User" : "Make Admin"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default UserList;
