import React from "react";
import IHomiesHero from "../../component/iHomies-hero";
import IHomiesUnique from "../../component/iHomies-unique";
import IHomiesFeatures from "../../component/iHomies-features";
import IHomiesMetrics from "../../component/iHomies-metrics";
import IHomiesPricing from "../../component/iHomies-pricing";
import IHomiesTestimonials from "../../component/iHomies-testimonials";
import IHomiesTrust from "../../component/iHomies-trust";
import IHomiesHeader from "../../component/iHomies-header";
import IHomiesFooter from "../../component/iHomies-footer";
import ScrollAnimation from "../../component/ScrollView";

function IHomies() {
  return (
    <>
      {/* <IHomiesHeader /> */}
      <ScrollAnimation>
        <IHomiesHero />
        <IHomiesTrust />
      </ScrollAnimation>
      <ScrollAnimation>
        <IHomiesUnique />
      </ScrollAnimation>

      <IHomiesFeatures />

      <ScrollAnimation>
        <IHomiesMetrics />
      </ScrollAnimation>
      <ScrollAnimation>
        <IHomiesPricing />
      </ScrollAnimation>
      <ScrollAnimation>
        <IHomiesTestimonials />
      </ScrollAnimation>
      <ScrollAnimation></ScrollAnimation>
    </>
  );
}

export default IHomies;
