import { Box, Button, Typography, Container } from "@mui/material";
import React from "react";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useAuth } from "../../context/AuthContext";
import AnimatedShinyText from "src/@/components/ui/animated-shiny-text";
import { cn } from "src/@/lib/utils";

function IHomiesHero() {
  const { onGoogleClick } = useAuth();
  return (
    <Box sx={{ backgroundColor: "#F3F7FB" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            height: "65vh",
          }}
        >
          {/* <Box
            sx={{
              border: "1px solid #000",
              backgroundColor: "#000",
              borderRadius: 7,
              p: "4px",
              width: "200px",
            }}
          >
            <Box
              sx={{
                borderRadius: 7,
                border: "1px solid #fff",
                backgroundColor: "#fff",
                // width: "80%",
                p: 0.1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  background:
                    "linear-gradient(271.22deg, #2100EE 0%, #6378C2 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Finance Question Bank
              </Typography>
            </Box>
          </Box>{" "} */}
          <Typography
            variant="h2"
            sx={{
              color: "#02015A",
              fontWeight: 600,
              px: { xs: 0, sm: 5, md: 10 },
              fontSize: { xs: "2rem", md: "3.25rem" },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Demystify Your Intimidating <br /> Finance Interview
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#4E5774",
              mt: 1.5,
              px: { xs: 0, sm: 20, md: 35 },
              fontSize: { xs: "0.85rem", md: "1rem" },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Real Interview Questions asked in Top IB and PE interviews. All
            questions are sourced real time from students on the ground taking
            interviews weekly.
          </Typography>
          {/* <Button
            onClick={onGoogleClick}
            sx={{
              height: "50px",
              width: "fit-content",
              // width: "130px",
              color: "#fff",
              backgroundColor: "#573CFF",
              p: "8px 12px",
              textTransform: "none",
              my: 2,
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#3D66FB",
              },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            <AnimatedShinyText>
              <span>Start Prep</span>
              <ArrowOutwardIcon sx={{ ml: 1 }} fontSize="small" />
            </AnimatedShinyText>
          </Button> */}
          <div
            className="z-10 flex mt-10 items-center justify-center"
            onClick={onGoogleClick}
          >
            <div
              className={cn(
                "group rounded-full border border-[#2b56fa] bg-neutral-100 text-base text-white transition-all ease-in hover:cursor-pointer hover:bg-neutral-200 dark:border-white/5 dark:bg-neutral-900 dark:hover:bg-neutral-800"
              )}
            >
              <AnimatedShinyText className="inline-flex items-center justify-center px-4 py-2 transition ease-out hover:text-neutral-600 hover:duration-300 hover:dark:text-neutral-400">
                <span>Start Prep</span>
                <ArrowOutwardIcon sx={{ ml: 1 }} fontSize="small" />
              </AnimatedShinyText>
            </div>
          </div>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesHero;
