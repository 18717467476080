import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Counter from "../Counter";

function IHomiesMetrics() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#EDF2F7",
        my: 2,
        py: 7,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: "120px",
          borderRadius: 7,
          backgroundColor: "#F3F7FB",
          my: 2,
        }}
      >
        <Typography
          fontWeight={600}
          sx={{
            textAlign: "center",
            borderRadius: 7,
            width: "120px",
            p: 0.5,

            backgroundColor: "#FFF",
            background: "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "Be Vietnam Pro",
          }}
        >
          METRICS
        </Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{ color: "#02015A", fontWeight: 600, fontFamily: "Be Vietnam Pro" }}
      >
        Numbers speaking for themselves
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: { xs: 2, md: 6 },
          my: 2,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#3D66FB",
              fontWeight: 600,
              fontFamily: "Be Vietnam Pro",
            }}
          >
            <Counter endValue={70} duration={1000} />+
          </Typography>
          <Typography sx={{ fontFamily: "Be Vietnam Pro" }}>
            Real Students helped
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#3D66FB",
              fontWeight: 600,
              fontFamily: "Be Vietnam Pro",
            }}
          >
            <Counter endValue={150} duration={1000} />+
          </Typography>
          <Typography sx={{ fontFamily: "Be Vietnam Pro" }}>
            Interview Questions
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#3D66FB",
              fontWeight: 600,
              fontFamily: "Be Vietnam Pro",
            }}
          >
            <Counter endValue={12} duration={1000} />+
          </Typography>
          <Typography sx={{ fontFamily: "Be Vietnam Pro" }}>
            Bank's Interview Questions
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default IHomiesMetrics;
