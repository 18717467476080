import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";

interface TestimonialCardProps {
  logoSrc: string;
  title: string;
  testimonial: string;
}

const TestimonialCard = ({
  logoSrc,
  title,
  testimonial,
}: TestimonialCardProps) => {
  return (
    <Card sx={{ padding: 2, borderRadius: 4 }}>
      <CardContent>
        {/* Top Section: Logo and Title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Box component="img" width={30} src={logoSrc} alt="Logo" />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontFamily: "Be Vietnam Pro" }}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
        </Box>

        {/* Testimonial Text */}
        <Box mt={2}>
          {/* <Tooltip
            // placement="right"
            // arrow
            // slotProps={{
            //   tooltip: {
            //     sx: {
            //       backgroundColor: "transparent",
            //       backgroundFilter: "blur(4px)",
            //     },
            //   },
            // }}
            // title={
            //   <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
            //     <Typography
            //       variant="body1"
            //       sx={{
            //         fontFamily: "Be Vietnam Pro",
            //         overflow: "hidden",
            //         textOverflow: "ellipsis",
            //         display: "-webkit-box",
            //         WebkitBoxOrient: "vertical",
            //       }}
            //     >
            //       {testimonial}
            //     </Typography>
            //   </Card>
            // }
          > */}
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Be Vietnam Pro",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: { xs: "unset", sm: 3 }, // Default to 3 lines
              maxHeight: { xs: "45rem", sm: "4.5rem" },
              transition: "max-height 0.3s ease-in-out",
              "&:hover": {
                WebkitLineClamp: "unset", // Default to 3 lines
                maxHeight: "45rem",
              },
            }}
          >
            {testimonial}
          </Typography>
          {/* </Tooltip> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
