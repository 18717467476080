"use client";

import { FC, ReactNode, useRef } from "react";
import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import { cn } from "src/@/lib/utils";
import logo from "../../../assets/iHomies-img/logo.png";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH } from "src/constant";

interface TextRevealByWordProps {
  text: string;
  className?: string;
}

export const TextRevealByWord: FC<TextRevealByWordProps> = ({
  text,
  className,
}) => {
  const targetRef = useRef<HTMLDivElement | null>(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const navigate = useNavigate();
  const goToHome = () => {
    navigate(PATH.home);
  };
  const words = text.split(" ");
  return (
    <div ref={targetRef} className={cn("relative z-0 h-[200vh]", className)}>
      <div
        className={
          "sticky top-0 mx-auto flex h-[50%] max-w-4xl items-center bg-transparent px-[1rem] "
        }
      >
        <div className="flex flex-col items-center">
          <Box
            onClick={goToHome}
            component="img"
            sx={{
              width: "150px",
              cursor: "pointer",
            }}
            src={logo}
          />
          <p
            ref={targetRef}
            className={
              "flex flex-wrap justify-center text-4xl font-bold text-black/20 dark:text-white/20 py-8 md:p-8 md:text-5xl  lg:text-6xl xl:text-6xl"
            }
          >
            {words.map((word, i) => {
              const start = i / words.length;
              const end = start + 1 / words.length;
              return (
                <Word key={i} progress={scrollYProgress} range={[start, end]}>
                  {word}
                </Word>
              );
            })}
          </p>
          <Typography
            sx={{
              color: "#68708C",
              px: { xs: 0, sm: 10, md: 18 },
              fontSize: { xs: "1rem", md: "1.125rem" },

              fontFamily: "Be Vietnam Pro",
            }}
          >
            1. User-friendly Interface
          </Typography>
          <Typography
            sx={{
              color: "#68708C",
              px: { xs: 0, sm: 10, md: 18 },
              fontSize: { xs: "1rem", md: "1.125rem" },

              fontFamily: "Be Vietnam Pro",
            }}
          >
            2. Real-Time Data Collection
          </Typography>
          <Typography
            sx={{
              color: "#68708C",
              px: { xs: 0, sm: 10, md: 18 },
              fontSize: { xs: "1rem", md: "1.125rem" },

              fontFamily: "Be Vietnam Pro",
            }}
          >
            3. Broad Bank & Industry Group Coverage
          </Typography>
        </div>
      </div>
    </div>
  );
};

interface WordProps {
  children: ReactNode;
  progress: MotionValue<number>;
  range: [number, number];
}

const Word: FC<WordProps> = ({ children, progress, range }) => {
  const opacity = useTransform(progress, range, [0, 1]);
  return (
    <span className="xl:lg-3 relative mx-1 lg:mx-2.5">
      <span className={"absolute opacity-30"}>{children}</span>
      <motion.span
        style={{ opacity: opacity }}
        className={"text-black dark:text-white"}
      >
        {children}
      </motion.span>
    </span>
  );
};

export default TextRevealByWord;
