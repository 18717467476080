import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./output.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
Sentry.init({
  dsn: "https://4b4fb41a0d20af752c3c525b89012307@o4507259153547264.ingest.de.sentry.io/4507259161477200",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/asia-south1-techmate-753f5.cloudfunctions.net\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Initialize Firebase

root.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
