import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";

// import img1 from "../../assets/iHomies-img/t1.png";
// import img2 from "../../assets/iHomies-img/t2.png";
// import img3 from "../../assets/iHomies-img/t3.png";
// import img4 from "../../assets/iHomies-img/t4.png";
// import img5 from "../../assets/iHomies-img/t5.png";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { COLORS } from "../../common/color";
import { Margin } from "@mui/icons-material";
import TestimonialCard from "./testimonialsCard";
import nyclogo from "../../assets/image/image 23.png";
import texaslogo from "../../assets/image/image 24.png";
import jpmorganlogo from "../../assets/image/image 8.png";
import fintechlogo from "../../assets/image/favicon.png";
function IHomiesTestimonials() {
  const fakeMonials = [
    {
      description:
        "I found FinancePrep on Reddit while prepping for my investment banking interviews. As a student from a non-target school, it gave me access to real interview questions and a platform to practice efficiently, helping me level the playing field with students from target schools.",
      title: "Non-Target School Student",
      src: texaslogo,
    },
    {
      description:
        "Despite coming from a target school with many resources, FinancePrep surprised me with its up-to-date, real interview questions. I was even asked some of the exact questions I practiced on FinancePrep in interviews at top firms.",
      title: "Target School Student",
      src: nyclogo,
    },
    {
      description:
        "As an analyst always preparing for the next round of interviews, I found FinancePrep to be a convenient, mobile-friendly resource that I could rely on to stay sharp and practice my technical skills during my busy schedule.",
      title: "JP Morgan M&A Analyst",
      src: jpmorganlogo,
    },
    {
      description:
        "FinancePrep.io provided me with an exceptional platform that simplified complex financial concepts. The personalized study plans, progress tracking, and expert-led webinars helped me pass my exams and feel well-prepared for the financial industry.",
      title: "Finance Exam Success",
      src: fintechlogo,
    },
    {
      description:
        "Using FinancePrep.io made mastering finance concepts much easier. The structured resources and expert webinars helped me pass my finance exams and feel confident in tackling real-world financial challenges.",
      title: "Finance Exam Mastery",
      src: fintechlogo,
    },
    {
      description:
        "FinancePrep.io transformed my exam preparation with its intuitive platform, interactive practice exams, and expert-led webinars. It kept me organized and on track, ensuring I passed my exams with high scores.",
      title: "Exam Preparation Transformation",
      src: fintechlogo,
    },
    {
      description:
        "With the help of FinancePrep.io, I was able to simplify complex topics and stay motivated with personalized study plans. The community support and expert-led webinars were invaluable in passing my finance exams and preparing for my career.",
      title: "Career Preparation",
      src: fintechlogo,
    },
    {
      description:
        "FinancePrep.io made a huge difference in my finance exam preparation. The engaging and interactive learning features helped me understand key concepts, and the expert-led webinars gave me additional insights. I passed my exams confidently thanks to FinancePrep.io.",
      title: "Finance Exam Confidence",
      src: fintechlogo,
    },
  ];

  // const testimonials = [
  //   {
  //     heading:
  //       '"I struggled to pass the interview questions for a financial institution, but thanks to Finance Prep, I became a top-notch candidate who met their requirements perfectly."',
  //     img: img1,
  //     author: "John Smith, Junior Accountant",
  //     bgColor: "#ECE5FF",
  //   },
  //   {
  //     heading:
  //       "The interview questions were intense, but Finance Prep's guidance was spot-on. I emerged as a leading candidate, thanks to their excellent preparation tools.",
  //     img: img2,
  //     author: "Sarah Johnson, Senior accountant at Z",
  //     bgColor: "#FBF7B8",
  //   },
  //   {
  //     heading:
  //       '"I struggled to pass the interview questions for a financial institution, but thanks to Finance Prep, I became a top-notch candidate who met their requirements perfectly."',
  //     img: img1,
  //     author: "John Smith, Junior Accountant",
  //     bgColor: "#ECE5FF",
  //   },
  //   {
  //     heading:
  //       "The interview questions were intense, but Finance Prep's guidance was spot-on. I emerged as a leading candidate, thanks to their excellent preparation tools.",
  //     img: img2,
  //     author: "Sarah Johnson, Senior accountant at Z",
  //     bgColor: "#FBF7B8",
  //   },
  // ];

  return (
    <Box sx={{ backgroundColor: "#EDF2F7", py: 5, mt: 10 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "170px",
              borderRadius: 7,
              backgroundColor: "#F3F7FB",
              my: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                textAlign: "center",
                borderRadius: 7,
                width: "170px",
                p: 0.5,

                backgroundColor: "#FFF",
                background:
                  "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              TESTIMONIALS
            </Typography>
          </Box>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              color: "#02015A",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
              fontWeight: 500,
              fontFamily: "Be Vietnam Pro",
            }}
            className="testimonials-scroll"
            id="testimonials-scroll"
          >
            Don’t take our word for it
          </Typography>
          <Box sx={{ width: "100%", mt: 2 }}>
            <Grid container spacing={2}>
              {fakeMonials.map((ele, ind) => (
                <Grid item xs={12} spacing={2} sm={6} md={4}>
                  <TestimonialCard
                    logoSrc={ele.src}
                    title={ele.title}
                    testimonial={ele.description}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ ml: "auto" }}
              >
                • All testimonials are anonymous due to banking regulations
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesTestimonials;
