import { Box, Container, Typography } from "@mui/material";
import React from "react";

import img1 from "../../assets/image/1.png";
import img2 from "../../assets/image/2.png";
import img3 from "../../assets/image/3.png";
import img4 from "../../assets/image/4.png";
import img5 from "../../assets/image/5.png";
import img6 from "../../assets/image/6.png";
import img7 from "../../assets/image/7.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./index.css";

function IHomiesTrust() {
  const logoSlides = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
  ];
  const sliderSettings = {
    arrows: true,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: -1000,
    speed: 5000,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "linear",
    infinite: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#02015A",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
          fontWeight: 500,
          fontFamily: "Be Vietnam Pro",
        }}
      >
        Trusted by students from
      </Typography>
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          whiteSpace: "nowrap",
          position: "relative",
        }}
      >
        <Box
          sx={{
            mt: 5,
            display: "flex",
            animation: "scrollLeft 20s linear infinite", // Custom continuous scroll animation
          }}
        >
          {logoSlides.concat(logoSlides).map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              sx={{
                maxHeight: "100px",
                height: "auto",
                objectFit: "contain",
                flexShrink: 0, // Prevent the image from shrinking
                padding: "10px",
              }}
            />
          ))}
        </Box>
      </Box>
      <style>
        {`
          @keyframes scrollLeft {
            0% {
              transform: translateX(0%);
              }
              100% {
                transform: translateX(-100%); /* This makes the second loop appear */
                }
                }`}
      </style>
    </Box>
  );
}

export default IHomiesTrust;
